@import '../../styles/variables';

.ContactForm {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5em;
  position: fixed;
  z-index: 5;

  &__dismiss {
    color: white;
    font-size: 3em;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 2em;
    background-color: white;
    border-radius: 5px;
    width: 100%;
    max-width: 75em;

    &Title {
      font-size: 2em;
      margin-bottom: 1em;
      letter-spacing: 3px;
      text-align: center;
    }

    &Body {
      display: flex;
      flex-direction: column;

      .InputWithLabel {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .Button {
        color: white;
        background: linear-gradient(234.39deg, #51ddb6 0%, #27926c 100%);
        align-self: center;
        margin-top: 1em;

        &--disabled {
          color: white;
          background: $light-gray;
          cursor: not-allowed;
        }
      }
    }
  }
}
