@import '../../styles/variables';

.Header {
  z-index: 4;
  height: 7.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 7.5em;
  font-size: 0.8em;
  font-weight: 900;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &__hamburgerMenu {
    display: none;
  }

  &__left {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 5em;
  }

  a {
    font-size: 1em;
    color: #17a073;
    text-decoration: none;
    text-shadow: 0 2px 10px white;
    letter-spacing: 0.5px;
  }

  a.Header__entry {
    font-size: 1.5em;
  }

  &__logo {
    height: 60%;
    width: auto;
    margin-right: 4em;
    cursor: pointer;

    svg {
      height: 100%;
      width: auto;
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__entry {
    cursor: pointer;
    margin-right: 5em;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }

  &__search {
    display: flex;
    align-items: center;

    @media (max-width: 1300px) {
      max-width: 30em;
    }

    @media (max-width: 1100px) {
      max-width: 25em;
    }

    &Left {
      display: flex;
      align-items: center;
      flex-grow: 1;

      div:last-child {
        flex-grow: 1;
      }
    }

    &Right {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &Button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $dark-green;
      border-radius: 5px;
      padding: 0.25em;
      color: white;
      display: flex;
      height: 3em;
      width: 3em;
      cursor: pointer;
      flex-shrink: 0;

      i {
        font-size: 1.5em;
      }
    }

    &Separator {
      height: 150%;
      width: 1px;
      background-color: $light-gray;
      margin: 0 1em;
    }
  }

  @media (max-width: 1000px) {
    padding: 0.5em 1em;

    &--expanded {
      background-color: white;
      position: fixed;
    }

    &__logo {
      margin-right: 0;
    }

    &__right {
      .Header__entry {
        display: none;
      }
    }

    &__left {
      margin-right: 0;
    }

    &__entry {
      display: flex;
      justify-content: center;
      color: $dark-green;
      width: 100%;
      padding: 1em;
      font-size: 1.5em;
      margin-right: 0;
      border-bottom: 1px solid $light-gray;
      font-weight: bold;

      span {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: $dark-green;
        text-shadow: none;
      }
    }

    &__hamburgerMenu {
      display: block;
      height: 1.5em;
      width: 1.5em;
      cursor: pointer;

      & > svg {
        height: 100%;
      }

      &Content {
        &Wrapper {
          position: fixed;
          height: calc(100vh - 5em);
          width: 100vw;
          z-index: 2;
          background-color: white;
          top: 5rem;
          left: 0;
          overflow: scroll;
        }

        display: flex;
        flex-direction: column;
        padding: 1em;
        padding-bottom: 10em;
        align-items: center;
      }
    }
  }
}
