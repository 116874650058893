@import '../../styles/variables';

.Footer {
  background: linear-gradient(234.39deg, #51ddb6 0%, #29a598 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 5em 0 3em 0;
  font-size: 0.8em;
  z-index: 2;
  flex-shrink: 0;

  @media (max-width: 1500px) {
    font-size: 0.65em;
  }

  @media (max-width: 1250px) {
    font-size: 0.55em;
  }

  a {
    color: white;
    text-decoration: none;
  }

  &__section {
    & > div {
      margin-bottom: 1em;

      &.Footer__title {
        margin-bottom: 2em;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__highlight {
    display: flex;
    align-items: center;
  }

  &__content {
    display: flex;
    margin-bottom: 3em;
    justify-content: space-between;
    width: 75%;
  }

  &__logo {
    img {
      width: 10em;
      height: auto;
    }
  }

  &__title {
    color: white;
    font-weight: 700;
    font-size: 1.25em;
  }

  &__recruitment {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  &__button {
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: #3dc1a8;
    background: white;
    padding: 0.75em 2em;
    font-weight: 900;
    cursor: pointer;

    a {
      color: white;
    }

    &:hover {
      box-shadow: $shadow;
    }

    svg {
      path {
        fill: #3dc1a8;
      }
      font-size: 1.5em;
      margin-left: 1em;
    }
  }

  &__socialNetworks {
    .Footer__title {
      text-align: center;
    }

    &Icons {
      a {
        margin-right: 2em;

        &:last-child {
          margin-right: 0;
        }

        svg,
        img {
          height: 34px;
          width: auto;
        }
      }
    }
  }

  &__contact {
    margin-right: 0;
    margin-bottom: 5em;

    .Footer__highlight {
      &:last-child {
        svg {
          margin-right: 1em;
        }
      }
    }

    svg {
      margin-right: 0.5em;
    }
  }

  @media (max-width: 1000px) {
    &__content {
      font-size: 1.5em;
      flex-direction: column;
      align-items: center;
    }

    &__section {
      margin-right: 0;
      margin-bottom: 3em;
      width: 20em;
      text-align: center;
    }

    &__highlight {
      justify-content: center;
    }

    &__logo {
      margin-bottom: 3em;
    }
  }

  &__contact {
    & > div {
      width: 100%;
    }
  }

  &__links {
  }
}
