@import '../styles/variables';

$header-height: 9em;

.Layout__content > .Home {
  padding-top: 0;
}

.Home {
  min-height: 100%;

  .Header {
    height: $header-height;
  }

  &__search {
    position: relative;
    height: 100vh;
    background-image: url('../../assets/images/background_title.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 10em;
    width: 100%;

    &Image {
      display: flex;
      align-items: center;
      z-index: 2;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      height: auto;
      max-width: 40%;

      img {
        pointer-events: none;
        user-select: none;
        max-height: 90vh;
        height: auto;
      }
    }

    &Text {
      &--mobileVisible {
        display: none;
      }

      &FirstLine {
        user-select: none;
        pointer-events: none;
        text-align: left;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 3.5em;
        color: white;
        text-shadow: 0 1px 8px #006830;
      }

      &SecondLine {
        user-select: none;
        pointer-events: none;
        text-align: left;
        font-family: 'Montserrat';
        font-weight: 900;
        font-size: 5.5em;
        color: white;
        text-shadow: 0 1px 8px #006830;
      }

      &First {
        position: absolute;
        z-index: 3;
        top: 20%;
        left: 35%;
        transform: translateX(-50%);
        text-align: center;
      }

      &Second {
        z-index: 3;
        position: absolute;
        top: 50%;
        right: 25%;
        transform: translateX(50%);
        text-align: center;
      }
    }
  }

  &__description {
    display: flex;
    justify-content: center;
    color: #009587;
    font-size: 2em;
    margin-bottom: 3em;

    h2 {
      font-weight: normal;
      max-width: 50%;
      text-align: center;
    }

    span {
      font-weight: bold;
    }
  }

  &__contact,
  &__flyer {
    padding: 0 15%;
    color: white;
    transform: translateY(50%);

    &Content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 4px solid white;
      border-radius: 10px;
      background: linear-gradient(234.39deg, #51ddb6 0%, #27926c 100%);
      box-shadow: 0 5px 20px 0 rgba(77, 186, 127, 0.39);
      padding: 3em 5em;
    }

    &Title {
      font-size: 1.7em;
      margin-bottom: 0.5em;
      font-weight: bold;
    }

    &Button {
      display: flex;
      align-items: center;
      border-radius: 5px;
      background-color: white;
      color: $dark-green;
      font-weight: 900;
      font-size: 0.8em;
      padding: 1em 3em;
      letter-spacing: 0.5px;
      cursor: pointer;
      flex-shrink: 0;
      margin-left: 2em;

      &:hover {
        box-shadow: $shadow;
      }
    }
  }

  &__solutions {
    display: flex;
    flex-direction: column;

    &Title {
      align-self: center;
      font-size: 2em;
      background-color: rgba(0, 149, 135, 0.05);
      width: 100%;
      text-align: center;
      padding-top: 7.5em;
      letter-spacing: 3px;
    }

    &Content {
      &Entry {
        display: flex;
        align-items: center;
        height: 30em;
        text-align: center;
        overflow: hidden;

        &:nth-child(3n + 1) {
          text-align: left;
          background-color: rgba(0, 149, 135, 0.05);

          .Home__solutionsContentEntryTextIcon {
            align-self: flex-start;
          }
        }

        &:nth-child(3n + 2) {
          text-align: right;
          background-color: rgba(0, 149, 135, 0.25);
          flex-direction: row-reverse;

          .Home__solutionsContentEntryTextIcon {
            align-self: flex-end;
          }
        }

        &:nth-child(3n) {
          background: linear-gradient(180deg, #00c7bc 0%, #009587 100%);
          color: white;
        }

        &--centered {
          justify-content: space-between;

          .Home__solutionsContentEntryImage--left {
            margin-right: 0;
          }

          .Home__solutionsContentEntryImage--right {
            margin-left: 1em;
          }
        }

        &Image {
          height: 100%;
          width: auto;

          &--left {
            margin-right: 10em;
            align-self: flex-start;

            &Shifted {
              height: 50%;
              margin-left: 5em;
              margin-top: 5em;
            }
          }

          &--right {
            margin-left: 10em;
            align-self: flex-end;
          }

          &--top {
            &Shifted {
              height: 65%;
              margin-bottom: 3em;
              align-self: flex-end;
            }
          }
        }

        &Text {
          display: flex;
          flex-direction: column;
          max-width: 40%;

          &Icon {
            margin-bottom: 2em;
            align-self: center;
          }

          &Title {
            font-size: 2em;
            margin-bottom: 1em;
            font-weight: bold;
          }

          &Body {
            line-height: 25px;
          }
        }
      }
    }
  }

  &__company {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5em;

    &Title {
      font-size: 2em;
      margin-bottom: 1em;
      letter-spacing: 3px;
      text-align: center;

      span {
        font-weight: bold;
      }
    }

    &Content {
      display: flex;

      &Entry {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        color: #009587;
        padding: 2em 0;
        width: 15em;
        position: relative;
        margin-right: 7.5em;

        &:last-child {
          margin-right: 0;
        }

        &Image {
          width: 12.5em;
        }

        &Title {
          text-align: center;
          position: absolute;
          bottom: 0;
          transform: translateY(100%);
          font-weight: bold;
        }
      }
    }
  }

  &__flyer {
    transform: none;
    padding-top: 5em;
    padding-bottom: 8em;

    &Content {
      justify-content: center;
      align-items: center;
    }

    &Title {
      margin-right: 2em;
      margin-bottom: 0;
    }

    &Button {
      height: 4em;
      width: 4em;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0 0 0pt 8pt rgba(255, 255, 255, 0.38);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      &:hover {
        box-shadow: 0 0 0pt 8pt rgba(255, 255, 255, 0.5);
      }
    }
  }

  @media (max-width: 1000px) {
    &__search {
      font-size: 0.6em;

      &Text {
        position: absolute;
        height: 100%;
        width: 100%;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.25em;

        &First,
        &Second {
          position: relative;
          top: unset;
          right: unset;
          left: unset;
          transform: unset;
          display: flex;

          .Home__searchTextFirstLine {
            text-align: center;
          }

          .Home__searchTextSecondLine {
            text-align: center;
          }
        }
      }

      &Image {
        max-width: 80vw;

        .parallax-inner {
          display: flex;
          justify-content: center;

          img {
            max-height: 80vh;
            max-width: 80vw;
          }
        }
      }
    }

    &__description {
      &Content {
        flex-direction: column;
      }
    }

    &__contact {
      padding: 0 5%;

      &Content {
        padding: 2em 2.5em;
        flex-direction: column;
      }

      &Text {
        text-align: center;
        margin-bottom: 1em;
      }

      &Button {
        justify-content: center;
        margin-left: 0;
      }
    }

    &__solutions {
      &Content {
        &Entry {
          flex-direction: column;
          width: 100%;
          height: auto;
          padding: 2em 0;

          &Text {
            text-align: center;
            align-items: center;
            max-width: 80%;
          }

          &:nth-child(3n + 1) {
            .Home__solutionsContentEntryTextIcon {
              align-self: unset;
            }
          }

          &:nth-child(3n + 2) {
            text-align: right;
            background-color: rgba(0, 149, 135, 0.25);
            flex-direction: column-reverse;
            padding-bottom: 0;

            .Home__solutionsContentEntryTextIcon {
              align-self: unset;
            }
          }

          &:nth-child(3n) {
            background: linear-gradient(180deg, #00c7bc 0%, #009587 100%);
            color: white;
            padding-bottom: 0;
          }

          &:nth-child(4n) {
            flex-direction: column-reverse;
            padding-bottom: 0;

            .Home__solutionsContentEntryImage {
              margin-left: -10em;
              width: 75%;
            }
          }

          &Image {
            &--left {
              margin-left: -8em;

              &Shifted {
                margin-left: -2.5em;
                margin-top: 0;
                margin-bottom: -3em;
                align-self: flex-start;
              }
            }

            &--right {
              margin-right: -8em;
            }

            &--top {
              &Shifted {
                width: 60%;
                height: auto;
                margin-bottom: 2em;
                align-self: flex-end;
              }
            }
          }
        }
      }
    }

    &__company {
      &Content {
        flex-direction: column;

        &Entry {
          margin-right: 0;
          margin-bottom: 4em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &__flyer {
      &Content {
        padding: 2em 1em;
        flex-direction: column;
      }

      &Title {
        margin-right: 0;
        margin-bottom: 1em;
        text-align: center;
      }
    }
  }
}
